/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Sauraj Kumar (ksauraj)",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Sauraj Kumar (ksauraj)",
    type: "website",
    url: "http://sauraj.cf",
  },
};

//Home Page
const greeting = {
  title: "Sauraj Kumar Singh",
  logo_name: "ksauraj",
  nickname: "ksauraj",
  subTitle:
    "Passionate 19-year-old exploring open-source horizons. Energized by the intricacies of Android and Linux. Computer Science explorer, avid project builder.",
  resumeLink:
    "https://drive.sauraj.eu.org/my_protfolio.jpg",
  portfolio_repository: "https://github.com/ksauraj",
  githubProfile: "https://github.com/ksauraj",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ksauraj",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Telegram",
    link: "https://t.me/Ksauraj",
    fontAwesomeIcon: "fa-telegram", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/c/SaurajGaming",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gitlab",
    link: "https://gitlab.com/ksauraj",
    fontAwesomeIcon: "fa-gitlab", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:me@sauraj.cf",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/k_sauraj",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/ksauraj__/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Build Roms & Kernels",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing and contributing to Android Open Source Project",
        "⚡ Former Official maintainer for Cipher OS & Pixel Plus UI.",
        "⚡ Released my own Android kernel named Xenon based on Neolit.",
      ],
      softwareSkills: [
        {
          skillName: "JAVA",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "AOSP",
          fontAwesomeClassname: "fa-android",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "fa-linux",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "C/C++",
          fontAwesomeClassname: "fa-cuttlefish",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Learning Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using Html & CSS",
        "⚡ Learning JS for backed development.",
        "⚡ Using Templates for this webpage, but surely will make my own someday....",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
      ],
    },
    {
      title: "Automation & Bots",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Writing my own bots and automated stuffs in Bash & Python.",
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying apps on Hereko & railway...",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Linode",
          fontAwesomeClassname: "fa-linode",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "Youtube & Related",
      fileName: "DesignImg",
      skills: [
        "⚡ Love to share my work on youtube.",
        "⚡ Always make my own content & try to be the first one to do so.",
        "⚡ Improve my video's content quality with every new video by seeking the lastest available stuffs and adapting them.",
      ],
      softwareSkills: [
        {
          skillName: "YouTube",
          fontAwesomeClassname: "simple-icons:youtube",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeaftereffect",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  ],
};

const degrees = {
  degrees: [
  ],
};

const certifications = {
  certifications: [
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Project I haved Worked on",
  description:
    "I have worked on making automated stuffs in action and making bots for general purpose stuffs..",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Sauraj Dumps",
          company: "Sauraj Dumps",
          company_url: "https://gitlab.com/sauraj-dumps",
          logo_path: "sauraj_dumps.png",
          duration: "Not Specific",
          location: "Patna, Bihar",
          description:
            "I am working on automating android dumps via my telegram bot, It uses the present dumper available on github like dumpyara Pheonix Dumper..",
          color: "#0879bf",
        },
        {
          title: "Ofp to Zip",
          company: "Personal Contribution",
          company_url: "https://github.com/ksauraj/ofp_to_zip",
          logo_path: "sauraj.png",
          duration: "Not Specific",
          location: "Patna, Bihar",
          description:
            "I have created an action script inspired from Area69 labs for converting ofp to zips. These zips are spft flashable....",
          color: "#9b1578",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {

};

const publicationsHeader = {
    
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "sauraj.png",
    description:
      "I am mostly available on telegram and you can tag me there anytime.........",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "Too much busy for now, will start typing soon...",
    link: "https://me.sauraj.cf",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Smnthg Smnthg, Patna, Bihar",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps?client=firefox-b-d&q=patna&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj3wcrmmtr2AhUh5DgGHShfD2QQ_AUoAXoECAIQAw",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+69 6969696969",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  competitiveSites,
  degrees,
  certifications,
  experience,
  skills,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
